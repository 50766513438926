<template>
  <SinglePostPreview :post-content="postContent" class="single-post-custom-dashboard-entry">
    <template #inner-wrapper>
      <div class="status-indicator">
        <div class="active" v-if="status !== 'published'">
          <svg-icon class="icon" name="edit"></svg-icon>
          <span class="text">
            {{ status }}
          </span>
        </div>
        <div :class="['published', { active: status === 'published' }]">
          <svg-icon class="icon" name="paper-plane"></svg-icon>
          <span class="text">
            Published
          </span>
        </div>
      </div>
    </template>
    <template #default>
      <div class="actions">
        <button
          class="action approve"
          v-if="!isApproved"
          @click="approvePost(postContent)"
          :disabled="isUpdatingApproval"
        >
          <b-spinner v-if="isUpdatingApproval" class="mr-1" small />
          <template v-else>
            <svg-icon class="icon" name="check-circle-design"></svg-icon>
            <span class="text">
              Approve
            </span>
          </template>
        </button>
        <button class="action view" @click="$router.push({ name: 'post.view', params: { id: postContent.postId } })">
          <svg-icon class="icon" name="eye"></svg-icon>
          <span class="text">
            View post
          </span>
        </button>
      </div>
    </template>
  </SinglePostPreview>
</template>

<script>
import SinglePostPreview from '~/components/SinglePostPreview';
import { get, isArray, forEach } from 'lodash';
import { UPDATE_POST_APPROVED_STATUS_MUTATION } from '~/graphql/mutations';
import { POST_CONTENTS_WITH_REPORT_QUERY } from '~/graphql/queries';
import { createNamespacedHelpers } from 'vuex';
import Swal from 'sweetalert2';

const workspaceNamespace = createNamespacedHelpers('workspace');

export default {
  name: 'single-post-custom-dashboard',
  props: {
    postContent: {
      type: Object,
      required: true,
    },
  },
  components: {
    SinglePostPreview,
  },
  data() {
    return {
      isUpdatingApproval: false,
    };
  },
  computed: {
    ...workspaceNamespace.mapState({
      workspace: state => state.current,
    }),
    status() {
      return get(this.postContent, 'status');
    },
    isApproved() {
      if (this.status === 'published') return true;
      return get(this.postContent, 'isApproved', false);
    },
  },
  methods: {
    async approvePost(postContent) {
      const status = postContent.isApproved ? 0 : 1;
      this.isUpdatingApproval = true;

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_POST_APPROVED_STATUS_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id: this.postContent.postId,
            isApproved: status,
          },
          refetchQueries: [
            {
              query: POST_CONTENTS_WITH_REPORT_QUERY,
              variables: {
                workspace: this.workspace.id,
                status: 'all',
              },
            },
          ],
        });

        Swal.fire({
          type: 'success',
          title: 'Success !!!',
          text: status ? 'Post approved succesfully' : 'Post dis-approved succesfully',
        });
      } catch ({ graphQLErrors: errors }) {
        let errorText = '';

        if (isArray(errors)) {
          forEach(errors, error => {
            if (error.message === 'validation') {
              forEach(error.extensions.validation, err => {
                errorText = err;
                return false;
              });
            }

            if (error.message === 'INVALID_POST') {
              errorText = 'Ooops.. post does not exists';
            }

            if (error.message === 'UNKNOWN_WORKSPACE_MEMBER') {
              errorText = 'Ooops.. user cannot execute such action';
            }
            return false;
          });
        }

        Swal.fire({
          type: 'error',
          title: 'Error !!!',
          text: errorText || 'An error occurred while saving post approval status',
        });
      }

      this.isUpdatingApproval = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.single-post-custom-dashboard-entry {
  ::v-deep {
    > .wrapper {
      > .status-indicator {
        height: 100%;
        display: flex;
        margin: 0 0 0 12px;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 150px;
        flex-shrink: 0;
        padding: 10px;

        > * {
          display: flex;
          width: 100%;
          border-radius: 10px;
          border: 1px solid #c4c4c4;
          background-color: #ffffff;
          justify-content: center;
          align-items: center;
          flex-wrap: nowrap;
          padding: 12px 0;

          > .icon {
            width: 22px;
            height: 22px;
            fill: #868686;
          }

          > .text {
            display: inline-flex;
            margin: 0 0 0 10px;
            color: #868686;
            font-size: 11px;
            text-transform: capitalize;
          }

          &.active {
            background-color: #68d86b;

            > .text {
              color: #ffffff;
            }

            > .icon {
              fill: #ffffff;
            }
          }

          &:not(:first-child) {
            margin: 10px 0 0 0;
          }
        }

        > .published:not(.active) {
          background-color: #dedede;

          > .icon {
            fill: #404040;
          }

          > .text {
            color: #404040;
          }
        }
      }
    }

    > .actions {
      height: 100%;
      display: flex;
      margin: 0 20px 0 20px;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 180px;
      padding: 10px;

      > * {
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #c4c4c4;
        background-color: #68d86b;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        padding: 15px 8px;
        box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        > .icon {
          width: 24px;
          height: 24px;
          fill: #ffffff;
        }

        > .text {
          display: inline-flex;
          margin: 0 0 0 12px;
          color: #ffffff;
          font-size: 15px;
          text-transform: capitalize;
        }

        &:disabled {
          background-color: #c4c4c4;
          cursor: not-allowed;
          pointer-events: none;

          > .icon {
            fill: #ffffff;
          }

          > .text {
            color: #ffffff;
          }
        }

        &:not(:first-child) {
          margin: 10px 0 0 0;
        }
      }

      > .view:not(:disabled) {
        background-color: #3f51b5;

        > .icon {
          width: 22px;
          height: 16px;
          transform: scale(1.5);
        }

        &:hover {
          background-color: #28378c;
          box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.4);
        }
      }

      > .approve:not(:disabled) {
        background-color: #68d86b;

        > .icon {
          width: 23px;
          height: 23px;
          transform: scale(1.5);
        }

        &:hover {
          background-color: #439245;
          box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}
</style>
