<template>
  <div class="custom-dashboard-wrap">
    <div class="fabs">
      <router-link
        class="posts button-wrap"
        :to="{ ...routeParams, name: 'custom-dashboard-posts' }"
        v-slot="{ href, navigate }"
        exact
      >
        <div>
          <a
            ref="posts-btn"
            :href="href"
            :class="[
              'fab-button',
              'posts',
              { active: isActive([{ name: 'custom-dashboard-posts' }, { path: '/approver/view/posts' }]) },
            ]"
            @click="navigate"
          >
            <svg-icon class="button-icon" name="posts" />
          </a>
          <b-tooltip
            customClass="custom-control"
            :target="() => $refs['posts-btn']"
            placement="right"
            :triggers="
              isActive([{ name: 'custom-dashboard-posts' }, { path: '/approver/view/posts' }]) ? 'manual' : 'hover'
            "
            :show="isActive([{ name: 'custom-dashboard-posts' }, { path: '/approver/view/posts' }])"
          >
            Posts
          </b-tooltip>
        </div>
      </router-link>
      <router-link
        v-if="isUserClientInWorkspace()"
        class="reports button-wrap"
        :to="{ name: 'reports' }"
        v-slot="{ href, navigate }"
        exact
      >
        <div>
          <a
            ref="reports-btn"
            :href="href"
            :class="['fab-button', 'reports', { active: isActive({ name: 'custom-dashboard-reports' }) }]"
            @click="navigate"
          >
            <svg-icon class="button-icon" name="activity" />
          </a>
          <b-tooltip
            customClass="custom-control"
            :target="() => $refs['reports-btn']"
            placement="right"
            :show="isActive({ name: 'custom-dashboard-reports' })"
          >
            Reports
          </b-tooltip>
        </div>
      </router-link>
      <router-link
        v-if="isUserClientInWorkspace()"
        class="calender button-wrap"
        :to="{ name: 'planner.calendar' }"
        v-slot="{ href, navigate }"
        exact
      >
        <div>
          <a
            ref="calender-btn"
            :href="href"
            :class="['fab-button', 'calender', { active: isActive({ name: 'custom-dashboard-calender' }) }]"
            @click="navigate"
          >
            <svg-icon class="button-icon" name="task" />
          </a>
          <b-tooltip
            customClass="custom-control"
            :target="() => $refs['calender-btn']"
            placement="right"
            :show="isActive({ name: 'custom-dashboard-calender' })"
          >
            Calender
          </b-tooltip>
        </div>
      </router-link>
      <router-link
        v-if="isUserClientInWorkspace()"
        class="accounts button-wrap"
        :to="{ name: 'settings.social' }"
        v-slot="{ href, navigate }"
        exact
      >
        <div>
          <a
            ref="accounts-btn"
            :href="href"
            :class="['fab-button', 'accounts', { active: isActive({ name: 'custom-dashboard-accounts' }) }]"
            @click="navigate"
          >
            <svg-icon class="button-icon" name="comment" />
          </a>
          <b-tooltip
            customClass="custom-control"
            :target="() => $refs['accounts-btn']"
            placement="right"
            :show="isActive({ name: 'custom-dashboard-accounts' })"
          >
            Accounts
          </b-tooltip>
        </div>
      </router-link>
      <router-link class="profile button-wrap" :to="{ name: 'settings.profile' }" v-slot="{ href, navigate }" exact>
        <div>
          <a
            ref="profile-btn"
            :href="href"
            :class="['fab-button', 'profile', { active: isActive({ name: 'custom-dashboard-profile' }) }]"
            @click="navigate"
          >
            <svg-icon class="button-icon" name="user-solid" />
          </a>
          <b-tooltip
            customClass="custom-control"
            :target="() => $refs['profile-btn']"
            placement="right"
            :show="isActive({ name: 'custom-dashboard-profile' })"
          >
            Profile
          </b-tooltip>
        </div>
      </router-link>
    </div>
    <div class="wrapper">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import { get, isArray, isObject, isString } from 'lodash';

const accessControl = createNamespacedHelpers('accessControl');

export default {
  name: 'CustomDashboard',

  metaInfo() {
    const userType = this.isUserClientInWorkspace() ? 'Client' : this.isUserApproverInWorkspace() ? 'Approver' : 'User';

    const title = `${userType} Dashboard`;
    return {
      title,
    };
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
    ...accessControl.mapGetters(['isUserClientInWorkspace', 'isUserApproverInWorkspace']),
    workspaceSlug() {
      return get(this.workspace, 'slug', '0');
    },
    routeParams() {
      return { params: { workspace: this.workspaceSlug } };
    },
    isActive() {
      return (data, type = 'name') => {
        if (isArray(data)) {
          return !!data.find(val => this.isActive(val) === true);
        } else if (isObject(data)) {
          const val = get(data, 'name', null);
          if (val) return this.isActive(val);

          return this.isActive(get(data, 'path', null));
        } else if (isString(data)) {
          return get(this.$route, type) === data;
        }
        return false;
      };
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~@/scss/mixins';
@import '~@/scss/variables';

.custom-dashboard-wrap {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  > .fabs {
    left: 60px;
    top: 151px;

    .button-wrap {
      &::v-deep {
        > .fab-button {
          width: 70px;
          height: 70px;
          color: #3f51b5;
          background-color: #ffffff;
          transition: all 0.3s ease-in-out;

          > .button-icon {
            width: 30px;
            fill: #3f51b5;
            transform: scale(1.3);
          }

          &.calender {
            > .button-icon {
              width: 24px;
              height: 29px;
            }
          }

          &.posts {
            > .button-icon {
              height: 25px;
            }
          }

          &:not(.disabled) {
            &:hover,
            &.active {
              background-color: #3f51b5;
              color: #ffffff;

              > .button-icon {
                fill: #ffffff;
              }
            }
          }

          &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            background-color: rgb(153, 153, 153);

            > .button-icon {
              fill: rgb(228, 228, 228);
            }

            &:hover {
              > .button-icon {
                fill: rgb(228, 228, 228);
              }
            }
          }
        }

        &:last-of-type .fab-button {
          margin-bottom: 0;
        }
      }
    }
  }

  > .wrapper {
    display: flex;
    margin: 0 !important;
    padding: 95px 0 0 150px;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
</style>

<style lang="scss">
.custom-control.tooltip {
  display: flex;
  > .arrow {
    &::before {
      border-right-color: #93a2f5;
    }
  }

  > .tooltip-inner {
    min-width: 130px;
    max-width: 130px;
    width: 130px;
    background-color: #93a2f5;
    color: #fdfdfd;
  }
}
</style>
