<template>
  <div class="custom-dashboard-posts">
    <div v-if="$apollo.queries.postContents.loading" class="bg-white shadow-sm rounded text-center py-5 loader">
      <spinner size="4" />
    </div>
    <div class="posts" v-else-if="postContents && postContents.length > 0 && !$apollo.queries.postContents.loading">
      <SinglePost v-for="postContent in postContents" :key="postContent.id" :post-content="postContent"></SinglePost>
    </div>
    <div v-else-if="postContents && postContents.length < 1 && !$apollo.queries.postContents.loading" class="empty">
      <div class="bg-white rounded text-center p-5">
        <img class="pt-3" width="300" src="@/assets/images/lost.svg" alt="" />
        <h4 class="pt-5">No posts here yet.</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { POST_CONTENTS_WITH_REPORT_QUERY } from '~/graphql/queries';
import { get } from 'lodash';
import SinglePost from '~/views/CustomDashboard/partials/SinglePost';

const workspaceNamespace = createNamespacedHelpers('workspace');

export default {
  name: 'custom-dashboard-posts',
  components: {
    SinglePost,
  },

  metaInfo() {
    return { title: 'Posts' };
  },

  data: function() {
    return {
      postContents: [],
      activeStatus: 'all',
    };
  },

  computed: {
    ...workspaceNamespace.mapState({
      workspace: state => state.current,
    }),
  },

  apollo: {
    postContents: {
      query: POST_CONTENTS_WITH_REPORT_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          status: this.activeStatus,
        };
      },
      update(data) {
        return get(data, 'postContentsWithReport', []);
      },
    },
  },

  watch: {
    isRockBottom(isRockBottom) {
      if (isRockBottom && this.posts && this.posts.length && this.loadMoreEnabled) {
        this.loadMore();
      }
    },
  },

  methods: {
    async loadMore() {
      this.offset = this.offset + 15;
      this.loadingMore = true;

      // Fetch more data and transform the original result
      await this.$apollo.queries.postContents.fetchMore({
        // New variables
        variables: {
          offset: this.offset,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newContents = fetchMoreResult.singlePosts.posts;
          const hasMore = fetchMoreResult.singlePosts.hasMore;
          const total = fetchMoreResult.singlePosts.total;

          this.loadMoreEnabled = hasMore;

          return {
            singlePosts: {
              __typename: previousResult.singlePosts.__typename,
              // Merging the tag list
              posts: [...previousResult.singlePosts.posts, ...newContents],
              total,
              hasMore,
            },
          };
        },
      });

      this.loadingMore = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dashboard-posts {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  max-width: 1500px;
  padding: 0;
  margin: 0 auto;

  > .posts {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    padding: 0;
  }

  > .loader,
  > .empty {
    width: 100%;
    min-height: 500px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #ffffff;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.3);
  }
}
</style>
